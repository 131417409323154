import { IPlatform } from 'types-generator/shared/interfaces'
import { getMode } from '../bridge_interface/bridge_interface'
import { KARGO_DIV_ID_PRODUCTION, KARGO_DIV_ID_TEST } from './constants'
import { neptuneDebug } from '../../../utilities'
import loadJS from 'load-js'
import { IPlatformSettingHeaderBidding } from 'types-generator/platform-definition/IPlatformConfiguration'
import { IPageSettingAdCampaigns, TPageSettingLanguage } from 'types-generator/page-configuration/IPageConfiguration'
import * as bridgeConstants from '../bridge_interface/constants'
import { AD_POSITION_FLOATING } from '../../../../process/configureAdBlocks'

const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_DIMENSIONS = [
  [300, 250],
  [320, 100],
  [320, 50]
]
export const DEFAULT_DIMENSIONS_STICKY = [
  [300, 50],
  [320, 50]
]

export const initPubwise = async (
  platform: IPlatform,
  headerBidding: IPlatformSettingHeaderBidding,
  campaigns: IPageSettingAdCampaigns,
  language: TPageSettingLanguage,
  baseUrl: string
) => {
  try {
    const w = window as any

    w.setup = setupPubwise(platform, headerBidding, campaigns, language)

    w.pbjs.que.push(function () {
      const categoryCodes = window.mp_globals.ad_config.iab_category_codes
      const categoryNames = window.mp_globals.ad_config.categories

      const pathnameMap = window.location.pathname.split('/')
      const filename = pathnameMap[pathnameMap.length - 1]
      const filenameWithoutExtension = filename.split('.html')[0]

      const appVersion =
        localStorage.getItem(bridgeConstants.LS_BRIDGE_APP_VERSION_MP) ??
        localStorage.getItem(bridgeConstants.LS_BRIDGE_APP_VERSION_MP_INTERNAL) ??
        ''

      w.pubwise.extra_dfp_params = {
        ...(w.setup.pubwise.dfp_params ?? {}),
        iab_category_codes: categoryCodes,
        iab_category_names: categoryNames,
        mode: w.getMode(),
        pathname: filenameWithoutExtension,
        mp_app_version: appVersion,
        bundle: localStorage.getItem('int_mp_appBundle')
      }
    })

    if (w.mp_globals.flags.allow_gpt) {
      w.mp_globals.helpers.injectScript(`${baseUrl}/js/pubwise_integration.js`)
      w.mp_globals.helpers.injectScript('//securepubads.g.doubleclick.net/tag/js/gpt.js', {
        async: true
      })
    }

    // Initialize Amazon bidding.
    if (w.mp_globals.features.amazon_ads && w.apstag) {
      w.apstag.init({
        pubID: '0a2e62aa-e1a3-4a86-bb42-43cbd1506fb4',
        adServer: 'googletag'
      })
    }

    if (w.mp_globals.flags.allow_pubwise && w.setup.pubwise.scripts) {
      let index = 1
      const PubwiseLoadedEvent = new Event('PubwiseLoadingSucceed')
      for (const script of w.setup.pubwise.scripts) {
        try {
          await loadJS([script.src])

          if (index === w.setup.pubwise.scripts.length) {
            document.dispatchEvent(PubwiseLoadedEvent)
            ;(window as any).pubwise.loaded = true
          }
          index++
        } catch (e) {
          ;(window as any).pubwise.enabled = false
          ;(window as any).pubwise.loaded = false

          const error = 'error loading pws.js script, setting enabled to false'
          console.error(error)
        }
      }
    }
  } catch (e) {
    console.error('initPubwise failed: %o', e)
  }
}

export const setupPubwise = (platform, pubwiseConfig, campaigns, language) => {
  try {
    return {
      language,
      pubwise: getPubwiseConfig(platform, pubwiseConfig, campaigns, language, navigator.userAgent)
    }
  } catch (e) {
    return {
      language,
      pubwise: {}
    }
  }
}

/**
 * Select pubwise config.
 */
export const getPubwiseConfig = (platform, headerBidding, campaigns, language, userAgent) => {
  const campaign = getCampaignByPlatform(platform, campaigns)

  switch (campaign.type) {
    case 'mode':
      return configureCampaignFsdMode(headerBidding, campaign, language)

    case 'single':
      return configureCampaignSingle(headerBidding, campaign, language)

    case 'split':
      return configureCampaignSplit(headerBidding, campaign, language)

    case 'useragent':
      return configureCampaignUserAgent(headerBidding, campaign, language, userAgent)

    default:
      throw new Error(`campaign type ${campaign.type} not supported`)
  }
}

const getCampaignByPlatform = (platform, settings) => {
  if (settings.custom) {
    for (const custom of settings.custom) {
      if (!custom.platforms) {
        console.error('NEPTUNE ERROR: Custom Pubwise Configuration Is Missing Platforms')
        continue
      }

      if (custom.platforms.includes(platform)) {
        return custom
      }
    }
  }

  return settings.default
}

/**
 * Setup pubwise when differentiating on which mode an FSD is delivered in.
 *
 * See PubwiseCampaignModeFilter in page-definition.ts for option details.
 */
const configureCampaignFsdMode = (headerBidding, campaign, language) => {
  const mode = getMode() || 'default'
  const modeConfigName = campaign?.filter?.[mode] || 'default'

  neptuneDebug(`configureCampaignFsdMode: mode filter ${mode} -> ${modeConfigName}`)

  const config = headerBidding?.[modeConfigName]?.[language] || headerBidding?.[modeConfigName]?.[DEFAULT_LANGUAGE]

  if (!config) {
    throw new Error(`configureCampaignFsdMode failed, config not found for mode ${mode} + ${language}`)
  }

  return headerBidding[modeConfigName][language]
}

/**
 * Setup pubwise when running in single "named" mode.
 *
 * See PubwiseCampaignSingle in page-definition.ts for option details.
 */
const configureCampaignSingle = (headerBidding, campaign, language) => {
  const config = headerBidding?.[campaign.config]?.[language] || headerBidding?.[campaign.config]?.[DEFAULT_LANGUAGE]

  if (!config) {
    throw new Error(`configureCampaignSingle failed, config not found for config ${campaign.config} + ${language}`)
  }

  return config
}

/**
 * Setup pubwise when running in split a/b testing mode.
 *
 * See PubwiseCampaignSplit in page-definition.ts for option details.
 */
const configureCampaignSplit = (headerBidding, campaign, language) => {
  // Check settings.
  if (!campaign.variantPercent) {
    throw new Error('configureCampaignSplit failed, variantPercent is missing')
  } else if (!campaign.control) {
    throw new Error('configureCampaignSplit failed, control is missing')
  } else if (!campaign.variant) {
    throw new Error('configureCampaignSplit failed, variant is missing')
  }

  // Check that configs exist.
  if (!headerBidding?.[campaign.control]?.[language]) {
    throw new Error('configureCampaignSplit failed, control pubwise config missing')
  } else if (!headerBidding?.[campaign.variant]?.[language]) {
    throw new Error('configureCampaignSplit failed, variant pubwise config missing')
  }

  const dice = Math.random() * 100
  const percent = parseInt(campaign.variantPercent, 10)
  const splitConfigName = dice <= percent ? campaign.variant : campaign.control

  neptuneDebug(
    `configureCampaignSplit: split campaign: ${campaign.control} / ${campaign.variant} % ${percent} = ${splitConfigName}`
  )

  const config = headerBidding?.[splitConfigName]?.[language] || headerBidding?.[splitConfigName]?.[DEFAULT_LANGUAGE]

  if (!config) {
    throw new Error(`configureCampaignSplit failed, config not found for config ${splitConfigName} + ${language}`)
  }

  return config
}

const configureCampaignUserAgent = (headerBidding, campaign, language, userAgent) => {
  if (!userAgent) {
    throw new Error(`configureCampaignUserAgent failed, user agent missing`)
  }

  for (const match of campaign.matchList) {
    if (userAgent.toLowerCase().includes(match.toLowerCase())) {
      return headerBidding?.[campaign.config]?.[language] || headerBidding?.[campaign.config]?.[DEFAULT_LANGUAGE]
    }
  }

  return headerBidding?.['default']?.[language] || headerBidding?.['default']?.[DEFAULT_LANGUAGE]
}

export interface AdBlockConfiguration {
  pubwiseConfig: any
  position: any
  adUnitID: any
  loadMethod: 'define_on_unlock' | '' | undefined
  dimensionsOverride: any
  outpageSlot?: {
    customMethod: true
    position: string
  }
}

/**
 * Configure an ad block.  Setup is either immediate or queued.
 */
export const adBlockConfigure = (config: AdBlockConfiguration) => {
  const { pubwiseConfig, adUnitID, loadMethod } = config

  if (!pubwiseConfig) {
    neptuneDebug(`Could not definite ad block for ${adUnitID}, pubwise config is empty`)
    return
  }

  // Define immediately.
  if (loadMethod !== 'define_on_unlock') {
    adBlockConfigureGPT(config)
    return
  }

  // Loading on unlock was created for ad ops as a result of getting marked as invalid traffic
  // with some advertisers.  This is essentially a feature that was added for testing but
  // we should keep it for now.
  ;(window as any).bridgeInterface.queueUnlockTask(() => {
    neptuneDebug(`Queueing ad slot definition call for ${adUnitID} until screen unlock event.`)
    adBlockConfigureGPT(config)
  })
}

/**
 * Configure Google Publisher Tag for an ad block.
 *
 * @param config the configuration for this ad block
 */
export const adBlockConfigureGPT = (config: AdBlockConfiguration) => {
  const { pubwiseConfig, adUnitID, position, dimensionsOverride } = config
  const w = window as any

  const ad_unit_path = getAdBlockPath(pubwiseConfig, adUnitID, position)
  const dfp_account_id = pubwiseConfig.dfp_account_id
  const lazyLoadEnabled = isLazyLoadEnabled(position, adUnitID, pubwiseConfig.lazyload_top)
  w.googletag.cmd.push(function () {
    if (config.outpageSlot && !config.outpageSlot.customMethod) {
      const w = window as any
      config.outpageSlot.position = w.googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR
    }
    const outPage = config.outpageSlot && config.outpageSlot.customMethod
    // defineSlot and defineOutOfPageSlot want different stuff:tm:
    const slotArgs =
      config.outpageSlot && !outPage
        ? [`/${dfp_account_id}/${ad_unit_path}`, config.outpageSlot.position]
        : [`/${dfp_account_id}/${ad_unit_path}`, getAdBlockDimensions(adUnitID, dimensionsOverride), adUnitID]
    const slotFunc = config.outpageSlot && !outPage ? w.googletag.defineOutOfPageSlot : w.googletag.defineSlot
    const adSlot = slotFunc(...slotArgs).addService(w.googletag.pubads())

    if (isStickyAd(adUnitID)) {
      adSlot.setCollapseEmptyDiv(true, true)
    }

    // Our pubwise integration library exposes a custom function that sets custom targeting values on gpt ad slots.
    // We'll do a check just so we can get a warning if it's not there, but that would be bad.
    // This allows us to set values like AAID and lat/long directly on the GPT slot.
    const setGPTAdTargeting =
      w.mp_globals && w.mp_globals.pubwise_integration && w.mp_globals.pubwise_integration.setGPTAdTargeting
    if (typeof setGPTAdTargeting !== 'function') {
      console.error('Missing globally exposed pubwise integration function for setting custom gpt targeting.')
    } else {
      setGPTAdTargeting(adSlot)
    }

    w.gptadslots[adUnitID] = adSlot
    w.googletag.enableServices()

    if (config.outpageSlot && !outPage) {
      w.googletag.cmd.push(function () {
        w.googletag.display(adSlot, [320, 50])
      })
    } else {
      w.googletag.display(adUnitID)
    }

    // If enabled, start Amazon bidding first and let the timeout between Amazon and Pubwise control things (this was recommended by Stephen from Pubwise).
    if (w.mp_globals.features.amazon_ads && w.apstag) {
      const awsSetup = {
        slots: [
          {
            slotID: adUnitID,
            slotName: `${dfp_account_id}/${ad_unit_path}`,
            sizes: getAdBlockDimensions(adUnitID, dimensionsOverride)
          }
        ],
        timeout: 2500
      }

      w.apstag.fetchBids(awsSetup, function () {
        w.googletag.cmd.push(function () {
          w.apstag.setDisplayBids()
        })
      })
    }

    // Rebuild bump.
    if (typeof w.pubwise != 'undefined' && w.pubwise.enabled === true) {
      w.pbjs.que.push(function () {
        const LAZY_LOAD_TYPE_LAZY_LOAD = 2
        const LAZY_LOAD_TYPE_IMMEDIATE_LOAD = 1
        const lazyLoadMode = lazyLoadEnabled ? LAZY_LOAD_TYPE_LAZY_LOAD : LAZY_LOAD_TYPE_IMMEDIATE_LOAD
        const rootMargin = [50, 0, 50, 0]
        const threshold = 0
        const mobileBreakpoint = 768
        const mobileMultipleMargin = 3
        w.pwRegisterLazyLoad(
          w.gptadslots[adUnitID],
          lazyLoadMode,
          rootMargin,
          threshold,
          mobileBreakpoint,
          mobileMultipleMargin
        )
      })
    } else {
      const loadAd = function () {
        neptuneDebug('Pubwise unavailable, refreshing add directly through GPT.')
        w.googletag.cmd.push(function () {
          w.googletag.pubads().refresh([w.gptadslots[adUnitID]])
        })
      }

      if (lazyLoadEnabled && typeof w.mp_globals.helpers.lazyload === 'function') {
        const el = document.querySelector(`#${adUnitID}`)
        w.mp_globals.helpers.lazyload(el, loadAd)
      } else {
        loadAd()
      }
    }
  })
}

/**
 * Configure Google Publisher Tag for a multiplex ad block.
 *
 * @param pubwiseConfig Selected config (page.header_bidding.campaign.language)
 * @param adUnitID      HTML element ID.
 * @param position      Vertical position on the page starting from zero.
 * @param layout        Block layout, edge vs tile.
 */
export const adBlockConfigureGPTMultiplex = (
  pubwiseConfig: any,
  adUnitID: string,
  position: number,
  layout: string
) => {
  const w = window as any

  const multiplexConfig = pubwiseConfig?.ad_unit_paths?.multiplex
  const ad_unit_path = multiplexConfig[layout]
  const dfp_account_id = pubwiseConfig.dfp_account_id

  neptuneDebug(
    `adBlockConfigureGPTMultiplex adUnitID ${adUnitID}, position: ${position}, layout: ${layout} ad_unit_path: ${ad_unit_path} dfp_account_id: ${dfp_account_id}`
  )

  w.googletag.cmd.push(function () {
    const adSlot = w.googletag
      .defineSlot(`/${dfp_account_id}/${ad_unit_path}`, ['fluid'], `${adUnitID}`)
      .addService(w.googletag.pubads())

    w.googletag.pubads().enableSingleRequest()
    w.googletag.enableServices()
    w.googletag.display(`${adUnitID}`)

    const el = document.querySelector(`#${adUnitID}`)

    w.mp_globals.helpers.lazyload(el, function () {
      w.mp_globals.pubwise_integration.setGPTAdTargeting(adSlot)
      w.googletag.pubads().refresh([adSlot])
    })
  })
}

// Helper functions.
// -----------------

/**
 * Sticky ads use different dimensions than other types.
 *
 * These might work better in platform settings.
 */
export const getAdBlockDimensions = (adUnitID, dimensions) => {
  let d = DEFAULT_DIMENSIONS

  // Override.
  if (dimensions.length) {
    d = dimensions
  } else if (isStickyAd(adUnitID)) {
    d = DEFAULT_DIMENSIONS_STICKY
  }

  return d
}

export const getAdBlockPath = (pubwiseConfig, adUnitID, position) => {
  if (isStickyAd(adUnitID)) {
    try {
      return pubwiseConfig.ad_unit_paths.kargo.edge
    } catch {
      console.log('getAdBlockPath: kargo sticky unit setup invalid, could not find ad_unit_paths.kargo.edge')
    }
  }

  if (position === AD_POSITION_FLOATING && pubwiseConfig.ad_unit_paths.floating) {
    return pubwiseConfig.ad_unit_paths.floating
  }

  return position == 0 ? pubwiseConfig.ad_unit_paths.top : pubwiseConfig.ad_unit_paths.bottom
}

/**
 * Hard coding the Kargo div id here is kind of nasty but it shouldn't really change (it changed).
 */
const isStickyAd = (adUnitID): boolean => {
  return [KARGO_DIV_ID_PRODUCTION, KARGO_DIV_ID_TEST].includes(adUnitID)
}

export const isLazyLoadEnabled = (position, adUnitID, lazyLoadTop = false): boolean => {
  return (lazyLoadTop || !(parseInt(position) === 0 || isStickyAd(adUnitID))) && position != AD_POSITION_FLOATING
}
