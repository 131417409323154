import metrics from './metrics'
import eventCollector from './event-collector'
import { EVENT_IMPRESSION } from './ad-event-collector'

export const collectWebVitalMetrics = () => {
  const start = window.pageLoadStart || new Date().getTime()
  window.addEventListener('load', () => {
    const diff = new Date().getTime() - start
    const labels = {}
    metrics.histogram(`page_load`, diff, labels)
  })

  window.addEventListener('DOMContentLoaded', () => {
    const diff = new Date().getTime() - start
    const labels = {}
    metrics.histogram(`page_dom_loaded`, diff, labels)
  })

  eventCollector.once(EVENT_IMPRESSION, () => {
    const diff = new Date().getTime() - start
    const labels = {}
    metrics.histogram(`time_to_first_imp`, diff, labels)
  })
}
