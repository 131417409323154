export const AD_POSITION_FLOATING = -100

const addBlockSettings = (block, counter) => {
  block.settings = block.settings ?? {}
  block.settings.ad_unit_position = counter // Position number for client side support.
  block.settings.dimensions_override = block.settings.dimensions_override || []
  block.settings.is_valid = true
  block.settings.show_header = block.settings.show_header == true ? true : false

  if (block.blockType === 'ad_unit_floating') {
    block.settings.ad_unit_id = 'mp_ad_unit_FLOATING'
    block.settings.ad_unit_position = AD_POSITION_FLOATING
  } else {
    if (counter === 0) {
      block.settings.ad_unit_id = 'mp_ad_unit_TOP'
    } else if (counter > 0 && counter < 6) {
      block.settings.ad_unit_id = `mp_ad_unit_BOTTOM__${counter}`
    } else {
      block.settings.is_valid = false
    }
  }


}

export default page => {
  if (!page.hasAds) return
  const { headerBlocks, blocks } = page

  let adUnitCounter = 0
  for (const block of [...headerBlocks, ...blocks]) {
    if (block.blockType === 'ad_unit' || block.blockType === 'ad_unit_floating') {
      addBlockSettings(block, adUnitCounter)
      if (block.blockType !== 'ad_unit_floating') {
        adUnitCounter++
      }
    }

    if (block.blockType === 'experiment_block') {
      const { control, variant } = block.settings
      const controlIsAd = control.blockType === 'ad_unit'
      const variantIsAd = variant.blockType === 'ad_unit'

      if (controlIsAd) addBlockSettings(control, adUnitCounter)
      if (variantIsAd) addBlockSettings(variant, adUnitCounter)

      if (controlIsAd || variantIsAd) adUnitCounter++
    }
  }
}
